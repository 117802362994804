import "./Header.css";
import "../App.css";

import { SWLink } from "../modules/SWLink";
import logo from "../imgs/logo.svg";

function Header(props) {
  return (
    <header className="Header">
      <div className="title">
        <div className="gradient">
          <h5 style={{ textAlign: "right" }}>Foothills</h5>
          <img src={logo} alt="Logo" />
          <h5 style={{ textAlign: "left" }}>Volleyball</h5>
        </div>
      </div>
      <div className="seasonSelector">
        <div>
          <a href="#" className="active">
            Indoor
          </a>
        </div>
        <div>
          <a href="http://beach.foothillsvolleyball.com">Beach</a>
        </div>
      </div>
      <div className="nav">
        <ul style={{ flexWrap: "wrap" }}>
          <li className={props.active === "Home" ? "active" : ""}>
            <SWLink to="/" content="Home" />
          </li>


          <li className={`dropdown ${props.active === "Watch" || props.active === "Calendar" ? "active" : ""}`}>
            <a href="#" style={{ pointerEvents: "none" }}>
              Our Schedule
            </a>
            <ul>
              <li className={props.active === "Watch" ? "active" : ""}>
                <SWLink to="/schedule" content="Watch Live" />
              </li>
              <li className={props.active === "Calendar" ? "active" : ""}>
                <SWLink to="/calendar" content="Calendar" />
              </li>
            </ul>
          </li>

          <li className={`dropdown ${props.active === "Teams" || props.active === "Coaches" ? "active" : ""}`}>
            <a href="#" style={{ pointerEvents: "none" }}>
              About Us
            </a>
            <ul>
              <li className={props.active === "Teams" ? "active" : ""}>
                <SWLink to="/teams" content="Teams" />
              </li>
              <li className={props.active === "Coaches" ? "active" : ""}>
                <SWLink to="/coaches" content="Coaches" />
              </li>
            </ul>
          </li>

          <li className={`dropdown ${props.active === "Records" || props.active === "Awards" ? "active" : ""}`}>
            <a href="#" style={{ pointerEvents: "none" }}>
              Recognition
            </a>
            <ul>
              <li className={props.active === "Records" ? "active" : ""}>
                <SWLink to="/records" content="Records" />
              </li>
              <li className={props.active === "Records" ? "active" : ""}>
                <SWLink to="/awards" content="Awards" />
              </li>
            </ul>
          </li>
          
          {/* <li className={props.active === "Store" ? "active" : ""}>
            <a href="/store">Store</a>
          </li> */}
        </ul>
      </div>
    </header>
  );
}

export default Header;
