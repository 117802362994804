import "../App.css";
import "./grass.css";

import { useEffect, useState } from "react";

import Matchup from "./Matchup";
import { db } from "../firebase";

// Give all the players a ranking
// Sort by UNIQUE TEAMMATES played with - try and get everyone to play w everyone

function GrassAdmin() {
  /* ************************************************ */
  /* STATE:
    /*
    /* * Mechanics
    /* * * players
    /* * * currentMatchups - In progress games already
    /*      generated by the program
    /* * * matches
    /*
    /* * UI State
    /* * * addPlayerValue - Add Player's current input value 
    /*
    /* ************************************************ */
  const [players, setPlayers] = useState([]);
  const [currentMatchups, setCurrentMatchups] = useState([]);
  const [matches, setMatches] = useState([]);

  const [addPlayerValue, setAddPlayerValue] = useState("");

  const [teamSize, setTeamSize] = useState(2);

  const [missingPlayers, setMissingPlayers] = useState([]);

  /* ************************************************ */
  /* UseEffect:
    /*
    /* Downloads the players from the DB
    /* calls setPlayers() to init the player array
    /*
    /* ************************************************ */

  useEffect(() => {
    db.collection("grass25")
      .get()
      .then((querySnapshot) => {
        let playerArr = [];
        querySnapshot.docs.forEach((doc) => {
          playerArr.push(doc.data());
        });
        playerArr.sort((a, b) => {
          let aAvg = isNaN(a.wins / (a.wins + a.losses))
            ? 0.5
            : a.wins / (a.wins + a.losses);
          let bAvg = isNaN(b.wins / (b.wins + b.losses))
            ? 0.5
            : b.wins / (b.wins + b.losses);
          if (aAvg != bAvg) {
            return bAvg - aAvg;
          } else {
            // Win/Loss Avg is equal, use set avg
            const aSetAvg = isNaN(a.setW / (a.setW + a.setL))
              ? 0
              : a.setW / (a.setW + a.setL);
            const bSetAvg = isNaN(b.setW / (b.setW + b.setL))
              ? 0
              : b.setW / (b.setW + b.setL);
            if (bSetAvg != aSetAvg) {
              return bSetAvg - aSetAvg;
            } else {
              // IF SET AVG IS EQUAL, USE PPS
              const aPPS = isNaN(a.pf / (a.setW + a.setL))
                ? 0
                : a.pf / (a.setW + a.setL);
              const bPPS = isNaN(b.pf / (b.setW + b.setL))
                ? 0
                : b.pf / (b.setW + b.setL);
              return bPPS - aPPS;
            }
          }
        });
        setPlayers(playerArr);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // SAVE
  const savePlayers = (updatedPlayers) => {
    //Save players to db
    for (const player of updatedPlayers) {
      db.doc("grass25/" + player.id)
        .set(player)
        .then(() => {
          //
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setPlayers([...updatedPlayers]);
  };

  // ADD
  const addPlayer = () => {
    let highID = 0;
    for (const player of players) {
      if (player.id > highID) {
        highID = player.id;
      }
    }
    savePlayers([...players, { name: addPlayerValue, id: highID + 1 }]);
    setAddPlayerValue("");
  };

  const createTeam = (roster) => {
    let rank = 0;
    for (let player of roster) {
      rank += player.rank;
    }
    return { roster, rank };
  };

  const updateRankings = ({ theseMatches }) => {
    const currentPlayers = [...players];
    //COMPILE PLAYER TOTALS
    for (const match of theseMatches) {
      for (let key = 0; key < currentPlayers.length; key++) {
        for (let i = 0; i < match.t1.roster.length; i++) {
          if (match.t1.roster[i].id == currentPlayers[key].id) {
            currentPlayers[key].wins += match.t1.setsWon == 2 ? 1 : 0;
            currentPlayers[key].losses += match.t2.setsWon == 2 ? 1 : 0;
            currentPlayers[key].setW += match.t1.setsWon;
            currentPlayers[key].setL += match.t2.setsWon;
            currentPlayers[key].pf +=
              match.t1.scores[0] + match.t1.scores[1] + match.t1.scores[2];
            currentPlayers[key].pa +=
              match.t2.scores[0] + match.t2.scores[1] + match.t2.scores[2];
            let theseTeammates = [];
            for (let player of match.t1.roster) {
              if (player.id != currentPlayers[key].id) {
                theseTeammates.push(player.id);
              }
            }
            currentPlayers[key].pastteammates = [
              ...currentPlayers[key].pastteammates,
              ...theseTeammates,
            ];
          } else if (match.t2.roster[i].id == currentPlayers[key].id) {
            currentPlayers[key].wins += match.t2.setsWon == 2 ? 1 : 0;
            currentPlayers[key].losses += match.t1.setsWon == 2 ? 1 : 0;
            currentPlayers[key].setW += match.t2.setsWon;
            currentPlayers[key].setL += match.t1.setsWon;
            currentPlayers[key].pf +=
              match.t2.scores[0] + match.t2.scores[1] + match.t2.scores[2];
            currentPlayers[key].pa +=
              match.t1.scores[0] + match.t1.scores[1] + match.t1.scores[2];
            let theseTeammates = [];
            for (let player of match.t2.roster) {
              if (player.id != currentPlayers[key].id) {
                theseTeammates.push(player.id);
              }
            }
            currentPlayers[key].pastteammates = [
              ...currentPlayers[key].pastteammates,
              ...theseTeammates,
            ];
          }
        }
      }
    }
    //RANK PLAYERS
    currentPlayers.sort((a, b) => {
      let aAvg = isNaN(a.wins / (a.wins + a.losses))
        ? 0.5
        : a.wins / (a.wins + a.losses);
      let bAvg = isNaN(b.wins / (b.wins + b.losses))
        ? 0.5
        : b.wins / (b.wins + b.losses);
      if (aAvg != bAvg) {
        return bAvg - aAvg;
      } else {
        // Win/Loss Avg is equal, use set avg
        const aSetAvg = isNaN(a.setW / (a.setW + a.setL))
          ? 0
          : a.setW / (a.setW + a.setL);
        const bSetAvg = isNaN(b.setW / (b.setW + b.setL))
          ? 0
          : b.setW / (b.setW + b.setL);
        if (bSetAvg != aSetAvg) {
          return bSetAvg - aSetAvg;
        } else {
          // IF SET AVG IS EQUAL, USE PPS
          const aPPS = isNaN(a.pf / (a.setW + a.setL))
            ? 0
            : a.pf / (a.setW + a.setL);
          const bPPS = isNaN(b.pf / (b.setW + b.setL))
            ? 0
            : b.pf / (b.setW + b.setL);
          return bPPS - aPPS;
        }
      }
    });
    savePlayers([...currentPlayers]);
  };

  // Given an array, find a suitable partner for players[0]
  // Players have a player.rank at this point and are sorted by GAMES PLAYED ascending
  // Given 9 players, rank 0 should play with rank 8, 1 with 7, ect
  // We want some randomness, so weight the players based
  // on how close they are to the correct partner ranking
  // RANKINGS START AT 0
  // past teammates don't matter
  // returns the index of the partner in the array
  const findPartnerBasedOnRank = (thesePlayers) => {
    let team = [];
    team.push(thesePlayers[0]);
    console.log("Added " + thesePlayers[0]);

    //RANK ALL PLAYERS
    thesePlayers.sort((a, b) => {
      let aAvg = isNaN(a.wins / (a.wins + a.losses))
        ? 0.5
        : a.wins / (a.wins + a.losses);
      let bAvg = isNaN(b.wins / (b.wins + b.losses))
        ? 0.5
        : b.wins / (b.wins + b.losses);
      if (aAvg != bAvg) {
        return bAvg - aAvg;
      } else {
        // Win/Loss Avg is equal, use set avg
        const aSetAvg = isNaN(a.setW / (a.setW + a.setL))
          ? 0
          : a.setW / (a.setW + a.setL);
        const bSetAvg = isNaN(b.setW / (b.setW + b.setL))
          ? 0
          : b.setW / (b.setW + b.setL);
        if (bSetAvg != aSetAvg) {
          return bSetAvg - aSetAvg;
        } else {
          // IF SET AVG IS EQUAL, USE PPS
          const aPPS = isNaN(a.pf / (a.setW + a.setL))
            ? 0
            : a.pf / (a.setW + a.setL);
          const bPPS = isNaN(b.pf / (b.setW + b.setL))
            ? 0
            : b.pf / (b.setW + b.setL);
          return bPPS - aPPS;
        }
      }
    });
    // Mark the rank of the players
    for (let i in thesePlayers) {
      thesePlayers[i].rank = i;
    }

    while (team.length < teamSize) {
      // console.log("")
      // console.log("-- Looking to add a player to: --")
      console.log("Team Size: " + team.length);
      // for(let i=0;i<team.length;i++){
      //     console.log("\t"+team[i].name+" - rank: "+team[i].rank)
      // }
      let tmpPlayers = [...thesePlayers];

      //Determine team ranking AND remove teammates from potential teammate pool
      let teamRank = 0;
      // console.log("Current TeamRANK b4"+teamRank)
      for (let teamPlayer of team) {
        for (let i = 0; i < tmpPlayers.length; i++) {
          if (teamPlayer.id == tmpPlayers[i].id) {
            // console.log("Adding: "+tmpPlayers[i].name+" with rank: "+tmpPlayers[i].rank)
            teamRank += parseInt(tmpPlayers[i].rank);
            tmpPlayers.splice(i, 1);
            break;
          }
        }
      }
      if (tmpPlayers.length == 1 && team.length == teamSize - 1) {
        team.push(tmpPlayers[0]);
        return team;
      }
      // console.log(teamRank+" / "+team.length+" = ")
      teamRank = teamRank / team.length;
      // console.log("Team rank: "+teamRank)
      // console.log("Team rank:"+teamRank+", size:"+team.length)
      const idealPartnerRank = thesePlayers.length - teamRank;
      // console.log("Ideal partner rank:"+idealPartnerRank)
      // console.log("Remaining player pool:"+tmpPlayers.length)
      // Give potential teammates a weight
      let totalWeights = 0;
      for (let i = 0; i < tmpPlayers.length; i++) {
        // console.log("\t"+tmpPlayers.length+" - ABS("+tmpPlayers[i].rank+"-"+idealPartnerRank+") = ")
        const thisWeight =
          tmpPlayers.length -
          Math.abs(tmpPlayers[i].rank - idealPartnerRank) +
          1;
        tmpPlayers[i].weight = thisWeight;
        tmpPlayers[i].testcount = 0;
        // console.log("\t\t"+tmpPlayers[i].name+" - rank: "+tmpPlayers[i].rank+", weight:"+tmpPlayers[i].weight)
        totalWeights += thisWeight;
      }

      // Pick new random teammate based on weights
      const rand = Math.random() * totalWeights;
      // console.log("Random weight result:"+rand)
      let randCount = 0;
      for (let i = 0; i < tmpPlayers.length; i++) {
        randCount += tmpPlayers[i].weight;
        // console.log("\t"+tmpPlayers[i].name+" (+"+tmpPlayers[i].weight+") = "+randCount)
        // weight = players - ABS(RANK - IDEAL) - 1
        if (rand <= randCount) {
          // console.log("\t\tAdding "+tmpPlayers[i].name+" ("+tmpPlayers[i].rank+") to team")
          team.push(tmpPlayers[i]);
          break;
        }
      }
    }
    return team;
  };

  const getAvailablePlayers = () => {
    //SET EMPTY PLAYER TOTALS
    const currentPlayers = players.map((player) => {
      player.wins = player.wins ? player.wins : 0;
      player.losses = player.losses ? player.losses : 0;
      player.setW = player.setW ? player.setW : 0;
      player.setL = player.setL ? player.setL : 0;
      player.pf = player.pf ? player.pf : 0;
      player.pa = player.pa ? player.pa : 0;
      player.pastteammates = player.pastteammates
        ? [...player.pastteammates]
        : [];
      return player;
    });

    //RANK PLAYERS
    currentPlayers.sort((a, b) => {
      let aAvg = isNaN(a.wins / (a.wins + a.losses))
        ? 0.5
        : a.wins / (a.wins + a.losses);
      let bAvg = isNaN(b.wins / (b.wins + b.losses))
        ? 0.5
        : b.wins / (b.wins + b.losses);
      if (aAvg != bAvg) {
        return bAvg - aAvg;
      } else {
        // Win/Loss Avg is equal, use set avg
        const aSetAvg = isNaN(a.setW / (a.setW + a.setL))
          ? 0
          : a.setW / (a.setW + a.setL);
        const bSetAvg = isNaN(b.setW / (b.setW + b.setL))
          ? 0
          : b.setW / (b.setW + b.setL);
        if (bSetAvg != aSetAvg) {
          return bSetAvg - aSetAvg;
        } else {
          // IF SET AVG IS EQUAL, USE PPS
          const aPPS = isNaN(a.pf / (a.setW + a.setL))
            ? 0
            : a.pf / (a.setW + a.setL);
          const bPPS = isNaN(b.pf / (b.setW + b.setL))
            ? 0
            : b.pf / (b.setW + b.setL);
          return bPPS - aPPS;
        }
      }
    });

    // SET PLAYERS FOR THE UI TABLE TO BE SORTED BY RANK
    setPlayers([...currentPlayers]);

    // Mark the rank of the players
    for (let i in currentPlayers) {
      currentPlayers[i].rank = i - 0;
    }

    // 1. Remove any player already in active matches from matchmaking
    // 1a. Remove any player not in attendance
    for (let i = currentPlayers.length - 1; i >= 0; i--) {
      let removed = false;
      for (let matchup of currentMatchups) {
        for (let j = 0; j < matchup.t1.roster.length; j++) {
          if (
            matchup.t1.roster[j].id == currentPlayers[i].id ||
            matchup.t2.roster[j].id == currentPlayers[i].id
          ) {
            console.log("Removing: " + currentPlayers[i].name);
            currentPlayers.splice(i, 1);
            removed = true;
            break;
          }
        }
        if (removed) {
          break;
        }
      }
      if (!removed && missingPlayers.includes(currentPlayers[i].id)) {
        currentPlayers.splice(i, 1);
      }
    }

    return currentPlayers;
  };

  const createTopCourt = () => {
    const currentPlayers = [...getAvailablePlayers()].slice(
      0,
      teamSize * 2 + teamSize / 2
    );

    const randTopPlayers = [currentPlayers[0], currentPlayers[1]];
    currentPlayers.splice(0, 2);
    for (let i = 0; i < teamSize * 2 - 2; i++) {
      const randomPick = Math.floor(Math.random() * currentPlayers.length);
      console.log("Random pick: " + randomPick);
      console.log(currentPlayers[randomPick]);
      randTopPlayers.push(currentPlayers[randomPick]);
      currentPlayers.splice(randomPick, 1);
    }

    console.log("Creating court with these players:");
    for (let player of randTopPlayers) {
      console.log(player.name);
    }
    console.log("These players randomly didn't get into the top court:");
    for (let player of currentPlayers) {
      console.log(player.name);
    }

    let teams = [];
    let matchups = [...currentMatchups];

    while (
      randTopPlayers.length >= teamSize * 2 ||
      (randTopPlayers.length >= teamSize && teams.length > 0)
    ) {
      console.log("Looking for new based on rank:");
      const newRoster = findPartnerBasedOnRank([...randTopPlayers]);
      console.log("Player pool size: " + randTopPlayers.length);
      console.log("Found new roster");
      for (let i = 0; i < newRoster.length; i++) {
        for (let j = 0; j < randTopPlayers.length; j++) {
          if (randTopPlayers[j].id == newRoster[i].id) {
            randTopPlayers.splice(j, 1);
            break;
          }
        }
      }
      console.log("Removed new roster from player pool");
      console.log("Player pool size: " + randTopPlayers.length);
      teams.push(createTeam(newRoster));
      console.log("# of teams: " + teams.length);
      if (teams.length == 2) {
        matchups.push({
          t1: { ...teams[0], scores: [], setsWon: 0 },
          t2: { ...teams[1], scores: [], setsWon: 0 },
        });
        teams.pop();
        teams.pop();
      }
      console.log("Final:");
      console.log(
        "Player pool size: " + randTopPlayers.length + " >=? " + teamSize
      );
      console.log("# of teams: " + teams.length);
    }
    setCurrentMatchups([...matchups]);
  };

  const generateRound = () => {
    const currentPlayers = getAvailablePlayers();

    currentPlayers.sort((a, b) => a.wins + a.losses - (b.wins + b.losses));

    let teams = [];
    let matchups = [...currentMatchups];

    while (
      currentPlayers.length > teamSize * 2 - 1 ||
      (currentPlayers.length > teamSize - 1 && teams.length > 0)
    ) {
      const newRoster = findPartnerBasedOnRank([...currentPlayers]);
      for (let i = 0; i < newRoster.length; i++) {
        for (let j = 0; j < currentPlayers.length; j++) {
          if (currentPlayers[j].id == newRoster[i].id) {
            currentPlayers.splice(j, 1);
            break;
          }
        }
      }
      teams.push(createTeam(newRoster));
      if (teams.length == 2) {
        matchups.push({
          t1: { ...teams[0], scores: [], setsWon: 0 },
          t2: { ...teams[1], scores: [], setsWon: 0 },
        });
        teams.pop();
        teams.pop();
      }
    }
    setCurrentMatchups([...matchups]);
  };

  return (
    <div>
      <div
        className="content"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Foothills Grass</h1>

        {currentMatchups.length > 0 ? (
          <div>
            <h4>Current Matches</h4>
            <h6>Matches to 15, tiebreaker to 11</h6>
          </div>
        ) : null}
        {currentMatchups.map((match, index) => {
          return (
            <Matchup
              match={match}
              index={index}
              currentMatchups={currentMatchups}
              setCurrentMatchups={setCurrentMatchups}
              matches={matches}
              setMatches={setMatches}
              key={`${match.t1.roster[0].id}-${match.t1.roster[1].id}-${match.t2.roster[0].id}-${match.t2.roster[1].id}`}
              updateRankings={updateRankings}
            />
          );
        })}
        {currentMatchups.length > 0 ? (
          <p>
            Players off:{" "}
            {players.map((player) => {
              let found = false;
              for (let matchup of currentMatchups) {
                for (let i = 0; i < matchup.t1.roster.length; i++) {
                  if (
                    matchup.t1.roster[i].id == player.id ||
                    matchup.t2.roster[i].id == player.id
                  ) {
                    found = true;
                  }
                }
              }
              if (!found && !missingPlayers.includes(player.id)) {
                return player.name + ", ";
              }
            })}
          </p>
        ) : null}

        <div
          style={{ margin: "16px 0", display: "flex", flexDirection: "column" }}
        >
          <div>
            <p>Available Players: {players.length - missingPlayers.length}</p>
            <h5>Team Size</h5>
            <div className="teamSize">
              <button
                onClick={() => setTeamSize(teamSize > 2 ? teamSize - 1 : 2)}
                disabled={teamSize == 2}
              >
                -
              </button>
              <h5>{teamSize}</h5>
              <button
                onClick={() => setTeamSize(teamSize < 6 ? teamSize + 1 : 6)}
                disabled={teamSize == 6}
              >
                +
              </button>
            </div>
            <h6>
              (
              {Math.floor(
                (players.length - missingPlayers.length) / (teamSize * 2)
              )}{" "}
              matches,{" "}
              {Math.floor(
                (players.length - missingPlayers.length) % (teamSize * 2)
              )}{" "}
              left over)
            </h6>
          </div>
          <button onClick={() => createTopCourt()} style={{ margin: "8px 0" }}>
            Create Top Court
          </button>
          <button onClick={() => generateRound()} style={{ margin: "8px 0" }}>
            Generate
          </button>
        </div>

        <h4>Players</h4>
        <table className="rankings">
          <thead>
            <tr>
              <td>Here?</td>
              <td>Name</td>
              <td>Ws</td>
              <td>Ls</td>
              <td>Sets W</td>
              <td>Sets L</td>
            </tr>
          </thead>
          <tbody>
            {players
              .sort((a, b) => (missingPlayers.includes(b.id) ? -1 : 1))
              .map((player, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={!missingPlayers.includes(player.id)}
                        onChange={() => {
                          const tmpMissPlayer = [...missingPlayers];
                          if (tmpMissPlayer.includes(player.id)) {
                            for (let i = 0; i < tmpMissPlayer.length; i++) {
                              if (tmpMissPlayer[i] == player.id) {
                                tmpMissPlayer.splice(i, 1);
                                break;
                              }
                            }
                            setMissingPlayers(tmpMissPlayer);
                          } else {
                            setMissingPlayers([...missingPlayers, player.id]);
                          }
                        }}
                      />
                    </td>
                    <td>{player.name}</td>
                    <td>{player.wins}</td>
                    <td>{player.losses}</td>
                    <td>{player.setW}</td>
                    <td>{player.setL}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <h4>Add Player</h4>
        <input
          value={addPlayerValue}
          onChange={(e) => setAddPlayerValue(e.target.value)}
        />
        <button onClick={() => addPlayer()}>Save Player</button>
      </div>
    </div>
  );
}

export default GrassAdmin;
