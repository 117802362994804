import "../App.css";
import "./grass.css";

import { useEffect, useState } from "react";

import { db } from "../firebase";

// Give all the players a ranking
// Sort by UNIQUE TEAMMATES played with - try and get everyone to play w everyone

function Grass() {
  const [players, setPlayers] = useState([]);
  const [avgPlayed, setAvgPlayed] = useState(0);

  useEffect(() => {
    db.collection("grass25")
      .get()
      .then((querySnapshot) => {
        let playerArr = [];
        let totalPlayed = 0;
        querySnapshot.docs.forEach((doc) => {
          const data = doc.data();
          let gamesPlayed = data.wins + data.losses;
          if (gamesPlayed > 0) {
            playerArr.push(doc.data());
            totalPlayed += gamesPlayed;
          }
        });
        setAvgPlayed(totalPlayed / playerArr.length);
        updateRankings(playerArr);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const updateRankings = (tmpPlayers) => {
    const currentPlayers = [...tmpPlayers];

    //RANK PLAYERS
    currentPlayers.sort((a, b) => {
      let aAvg = isNaN(a.wins / (a.wins + a.losses))
        ? 0.5
        : a.wins / (a.wins + a.losses);
      let bAvg = isNaN(b.wins / (b.wins + b.losses))
        ? 0.5
        : b.wins / (b.wins + b.losses);
      if (aAvg != bAvg) {
        return bAvg - aAvg;
      } else {
        // Win/Loss Avg is equal, use set avg
        const aSetAvg = isNaN(a.setW / (a.setW + a.setL))
          ? 0
          : a.setW / (a.setW + a.setL);
        const bSetAvg = isNaN(b.setW / (b.setW + b.setL))
          ? 0
          : b.setW / (b.setW + b.setL);
        if (bSetAvg != aSetAvg) {
          return bSetAvg - aSetAvg;
        } else {
          // IF SET AVG IS EQUAL, USE PPS
          const aPPS = isNaN(a.pf / (a.setW + a.setL))
            ? 0
            : a.pf / (a.setW + a.setL);
          const bPPS = isNaN(b.pf / (b.setW + b.setL))
            ? 0
            : b.pf / (b.setW + b.setL);
          return bPPS - aPPS;
        }
      }
    });
    setPlayers([...currentPlayers]);
  };

  return (
    <div>
      <div
        className="content"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center", width: "100%" }}>
          <h1>2025 Grass League</h1>
          <p>Games played required: {(avgPlayed * 0.75).toFixed(0)}</p>
        </div>
        <table className="rankings">
          <thead>
            <tr>
              <td>Name</td>
              <td>W %</td>
              <td>Ws</td>
              <td>Ls</td>
              {/* <td>Games Behind</td> */}
              <td>Sets W</td>
              <td>Sets L</td>
              {/* <td>PF</td>
                    <td>PA</td>
                    <td>PPS</td> */}
              <td>PDPS</td>
            </tr>
          </thead>
          <tbody>
            {players
              .filter((p) => p.wins + p.losses >= avgPlayed * 0.75)
              .map((player, index) => {
                return (
                  <tr key={index}>
                    <td>{player.name}</td>
                    <td>
                      {(player.wins / (player.wins + player.losses)).toFixed(3)}
                    </td>
                    <td>{player.wins}</td>
                    <td>{player.losses}</td>
                    <td>{player.setW}</td>
                    <td>{player.setL}</td>
                    {/* <td>{player.pf}</td>
                        <td>{player.pa}</td>
                        <td>{(player.pf/(player.setW+player.setL)).toFixed(1)}</td> */}
                    <td>
                      {(
                        (player.pf - player.pa) /
                        (player.setW + player.setL)
                      ).toFixed(1)}
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td colSpan={7}>
                <strong>Not Enough Games Played</strong>
              </td>
            </tr>
            {players.map((player, index) => {
              if (player.wins + player.losses >= avgPlayed * 0.75) {
                return null;
              }
              return (
                <tr key={index}>
                  <td>{player.name}</td>
                  <td>
                    {(player.wins / (player.wins + player.losses)).toFixed(3)}
                  </td>
                  <td>{player.wins}</td>
                  <td>{player.losses}</td>
                  <td>{player.setW}</td>
                  <td>{player.setL}</td>
                  {/* <td>{player.pf}</td>
                        <td>{player.pa}</td>
                        <td>{(player.pf/(player.setW+player.setL)).toFixed(1)}</td> */}
                  <td>
                    {(
                      (player.pf - player.pa) /
                      (player.setW + player.setL)
                    ).toFixed(1)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Grass;
